import React from "react";
import AmazonLogo from "../media/amazon.svg";
import FlipkartLogo from "../media/flipkart-icon.svg";

const BuyNowSection = (props) => {
  return (
    <div
      ref={props.reference}
      className="bg-white p-16 flex justify-center items-center gap-3 flex-col lg:flex-row"
    >
      <a
        href="https://amzn.eu/d/8sZKXOl"
        target="_blank"
        rel="nofollow noreferrer"
        className="btn bg-[#fa643f] border-[#fa643f] hover:bg-white hover:text-black rounded-3xl font-bold px-8"
      >
        Order Now
        <img src={AmazonLogo} alt="" className="h-6 ml-2" />
      </a>
      <a
        href="https://www.flipkart.com/ahman-naphthalene-balls/p/itm193aabda51a0f"
        target="_blank"
        rel="nofollow noreferrer"
        className="btn bg-[#fa643f] border-[#fa643f] hover:bg-white hover:text-black rounded-3xl font-bold px-8"
      >
        Order Now
        <img src={FlipkartLogo} alt="" className="h-6 ml-2" />
      </a>
    </div>
  );
};

export default BuyNowSection;
