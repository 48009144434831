import Header from "./Header";
import Section from "./Section";
import { useEffect, useRef } from "react";
import BuyNowSection from "./BuyNowSection";

const Home = () => {
  const BuySectionRef = useRef(null);

  useEffect(() => {
    document.title = "Ahman";
  }, []);

  return (
    <>
      <Header reference={BuySectionRef} />
      <Section />
      <BuyNowSection reference={BuySectionRef} />
    </>
  );
};

export default Home;
