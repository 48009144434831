import React from "react";
import mainImage from "../media/ahman-main.webp";

const Header = (props) => {
  const scrollDown = () => {
    window.scrollTo({
      top: props.reference.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="hero min-h-screen">
        <div className="hero-content flex-col lg:flex-row-reverse justify-between w-full max-w-6xl px-10">
          <img
            src={mainImage}
            className="md:max-w-md max-w-screen-xs rounded-lg"
            alt=""
          />
          <div className="lg:w-1/2">
            <h1 className="text-4xl sm:text-5xl font-bold">
              Naphthalene Balls
            </h1>
            {/* <p className="py-6">
              Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda
              excepturi exercitationem quasi. In deleniti eaque aut repudiandae
              et a id nisi.
            </p> */}
            <button
              onClick={scrollDown}
              className="my-6 btn bg-[#fa643f] border-[#fa643f]"
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
