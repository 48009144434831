import React from "react";

const Modal = (props) => {
  return (
    <>
      <input type="checkbox" checked={props.isModal} class="modal-toggle" />
      <div class="modal modal-bottom sm:modal-middle">
        <div class="modal-box">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Thank You!
          </h3>
          <p class="text-sm text-gray-500 mt-2">
            Your message has been sent. We will get back to you as soon as we
            can.
          </p>
          <div class="mt-4">
            <btn
              class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => props.setIsModal(false)}
            >
              Yay!
            </btn>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
