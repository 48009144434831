import React from "react";
import Image from "../media/ahman.webp";

const Section = () => {
  return (
    <div className="mx-4 lg:mx-10 rounded-[32px] bg-slate-200 flex justify-between items-center gap-1 flex-col lg:flex-row p-5 lg:p-10">
      <img
        src={Image}
        className="md:max-w-md h-full max-w-screen-xs rounded-lg"
        alt=""
      />
      <div className="lg:w-1/2 lg:mx-10">
        {/* <h1 class="text-4xl sm:text-5xl font-bold">Naphthalene Balls</h1> */}
        <p className="py-6 text-xl font-bold">
          <ul>
            <li className="mb-2">
              Suitable for Cupboards, Bookshelves and Toilets etc.
            </li>
            <li className="mb-2">Controls odour in washrooms</li>
            <li className="mb-2">
              Helps eliminate the insects and maintain pleasant fragrance within
              the clothes
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default Section;
